<template>
<div v-if="days" class="d-flex align-content-start flex-wrap"> <unicon name="calendar-alt" fill="royalblue" class="mt-2"></unicon>
<div class="p-2" v-for="(item, index) in days" :key="index">
<span :key="index"  :class="item.today === 1? 'badge badge-success' : 'badge badge-warning'">{{item.name }}</span>
</div> 
</div>
</template>
<script>

export default {
  name: 'weekDays',
  props: ['data'],
  data(){
      return {
          days:null
      }
  },
  mounted (){
      let arr = this.data.split(",");
      var date=new Date();
      var daysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      arr = this.arraymove(arr,6,0);
      //alert(this.data);
       let temp= Array();
      for(let i=0;i<arr.length;i++){
        
          if(arr[i]=='true'){
          //alert(arr[i]);
              temp.push({
                'name':daysName[i],
                'today': i===date.getDay()?1:0
              });
            
          }
      }
       this.days = temp;
     
     // this.days = JSON.parse(this.product.config);
  },
  methods: {
    arraymove(arr, fromIndex, toIndex)  {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
}
}
}
</script>

<style>

</style>