
<script setup>
  import VLazyImage from "v-lazy-image";
</script>
<template>
  <div class="product">
    
    
    <div :class="product-inner-green">
      <div class="product-image-wrap">
      <h4>{{ product.title }}</h4>
   <div class="d-flex flex-row" >
<div class="p-2" style="width:40%">
       <VLazyImage  :src="url" class="image" 
       @click="onImageClick(product.id)" 
       :key="product.id" @error="replaceByDefault"
        />
</div>
<div class="p-2" style="width:60%">
<p v-if="config.speciality"> <unicon name="stethoscope" fill="royalblue"></unicon> {{config.speciality.substring(0,50) }}</p>
<p  v-if="config.universities"><unicon name="university" fill="royalblue"></unicon> {{config.universities.substring(0,50) }}</p>
<router-link class="btn btn-primary" :to="{ name: 'profile-details', params: { id: product.id }}">Details</router-link>
</div>


   </div>
      </div>
    
      <div class="product-detail">
         <WeekDays :data="config.weekDays"  v-if="config.weekDays" />
        
      
        <p v-if="config.startTime" class="strong"> <unicon name="clock-ten" fill="royalblue"></unicon>
{{ config.startTime }} -{{ config.endTime }}</p>
      </div>
    </div>
     <hr/>
  </div>
 
</template>

<script>
// uniUniversity, uniGraduationCap, uniMapMarkerAlt, uniMapMarker, uniStethoscope
import WeekDays from './utils/WeekDays';
export default {
  name: 'product',
  inject: ['imgUrl'],
  props: ['product','journal'],
   components: {
   WeekDays
  },
  data(){
      return {
          url: this.imgUrl+"journal/"+this.product.id,
          config:null
      }
  },
  created (){
      this.config = JSON.parse(this.product.config);
  },
  methods: {
    getImageClass: function(i) {
        return {
            'fullWidthImage': this.fullWidthImageIndex === i
        };
    },
    replaceByDefault(e){
      
      this.url =   this.imgUrl+'404.png';
        // alert( this.url);
    },
    onImageClick: function(i) {
        if (this.fullWidthImageIndex === i) {
            this.fullWidthImageIndex = null;
        } else {
            this.fullWidthImageIndex = i;
        }
    }
}
}
</script>

<style>

.fullWidthImage {
  width: 300px !important;
  height: 400px !important;
  display: flex;
  position: absolute;
}

  .product {
    flex: 1 1 33.333%;
    width: 100%;
    padding: 25px;
  }
  .product-inner {
    position: relative;
    padding: 25px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    perspective: 1000px;
  }
  .product-inner.green {
    background-image: linear-gradient(to bottom right, #24D484, #116432);
  }
  .product-inner.blue {
    background-image: linear-gradient(to bottom left, #24D484, #2474C4 70%);
  }
  .product-inner.pink {
    background-image: linear-gradient(to bottom right, #F444A4, #1168D4);
  }
  .product-text-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    perspective: 1000px;
  }
  
  .product-text-wrap h2 {
    color: #313131;
    font-size: 128px;
    font-weight: 900;
    opacity: 0.2;
    transform-origin: center;
  }
  .product-image-wrap {
    position: relative;
    z-index: 1;
    transform-origin: center;
  }
  .product-image-wrap .image {
    width: 100%;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.25));
  }
  .product-detail {
    background-color: #FFF;
    padding: 25px;
    margin: 0px -25px -25px;
  }
  .product-detail h2 {
    font-size: 20px;
    font-weight: 700;
    color: #676767;
    margin-bottom: 15px;
  }
  .product-detail p {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
    color: #676767;
  }
  


</style>